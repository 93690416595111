body, #root {
  min-height: 100vh;
}

body, textarea {
  font-family: Arial, Helvetica, sans-serif;
}

#root {
  margin: 0 auto;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  background-color: #ebebeb;
  color: #000;
}

a {
  color: #000;
  font-weight: bold;
}

h2 {
  margin: 1em 0 0.5em;
}

ul {
  margin: 0 0 1em 1em;
  padding: 0;
}

p {
  margin: 1rem 0;
}
