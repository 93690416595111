.Modal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  height: 100vh;
  justify-content:center;
  position: absolute;
  width: 100vw;
  z-index: 999;
}

.Modal-innerContainer {
  background-color: white;
  max-width: 1200px;
  padding: 2rem;

  button {
    cursor: pointer;
    background-color: #ffb71d;
    border: none;
    color: black;
    padding: 1rem 2rem;
  }
}
