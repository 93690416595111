html {
  font-size: 12px;
}

.AppContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.App {
  min-height: 100vh;
  max-width: 1400px;
  width: 100%;
}

h1 {
  font-size: 1.67rem;
  margin-top: 0;
}

h2 {
  font-size: 1.67rem;
}

h3 {
  font-size: 1.5rem;
}
