.Header {
  background: #ffb91d;
  height: 3.75rem;
}

.Header-logo {
  padding-left: 12px;
}

.Header-logo img {
  display: inline-block;
  height: 2.42rem;
  vertical-align: middle;
}

.Header-menu {
  box-sizing: border-box;
  float: left;
  height: 3.75rem;
  padding: 0;
  margin: 0;
}

.HeaderRight {
  float: right;
}

.Header-menu span {
  display: none;
}

.Header-menu li {
  box-sizing: border-box;
  display: inline-block;
  height: 3.75rem;
  line-height: 3.75rem;
  min-width: 3.75rem;
  vertical-align: top;
}

.Header-menu a, .Header-menu li > div {
  background: #ffb91d;
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 0.75rem;
  font-weight: normal;
  height: 3.75rem;
  line-height: 1.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.Header-rstripe {
  border-right: 1px solid #FA9E0B;
}

.Header-menu li.Header-notifications,
.Header-menu li.Header-contact {
  display: none;
}

.HeaderRight li {
  border-left: 1px solid #fa9e0b;
  width: 3.75rem;
}

.HeaderRight li.Header-language {
  float: right;
  width: 4.1667rem;
}

.Header-menu li.HeaderRight-cannot-find {
    width: 7.5rem;
}

.HeaderRight-cannot-find a {
  background: #000;
  color: #fff;
  line-height: 11px;
  height: 37px;
  padding: 8px 0.4167rem 0 0.4167rem;
}

.Header-icon {
  display: block;
  height: 2rem;
  text-align: center;
}

.Header-icon img {
  display: block;
  margin: 0 auto 0 auto;
  padding-top: 0.75rem;
}

.Header-home-icon {
  height: 2.0833rem;
}

.Header-notifications-icon {
  height: 1.1667rem;
}

.Header-enquiries-icon {
  height: 1.8333rem;
}

.Header-contact-icon {
  height: 1.25rem;
}



.Header-language-icon {
  height: 1.8333rem;
}

img.Header-menu-icon {
  padding-top: 1rem;
  height: 1.6667rem;
}

img.Header-menu-cross-icon {
  padding-top: 1rem;
  height: 1.5rem;
}

@media screen and (min-width: 1200px) {
  .Header-menu {
    width: 50%;
  }

  .Header-menu span {
    display: inline;
  }

  .Header-menu li.Header-notifications,
  .Header-menu li.Header-contact {
    display: inline-block;
  }

  .HeaderRight li {
    width: 20%;
  }

  .HeaderRight li.Header-language {
    width: 20%;
  }

  .Header-menu li.HeaderRight-cannot-find {
    width: 20%;
  }



  .Header-icon img {
    padding-top: 0.5rem;
  }

  .Header-home-icon {
    height: 1.3333rem;
  }

  .Header-enquiries-icon {
    height: 1.1667rem;
  }

  .Header-language-icon {
    height: 1.0833rem;
  }

  img.Header-menu-icon {
    padding-top: 0.6667rem;
    height: 1.0833rem;
  }

  img.Header-menu-cross-icon {
    padding-top: 0.75rem;
    height: 0.9167rem;
  }
}
